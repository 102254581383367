/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// Dependencias
import React, { Component } from 'react';
import Typed from 'typed.js';
import appConfig from '../../App.config';
/* global $ */

// Hojas de Estilos
import './IntroSearch.scss';

class IntroSearch extends Component {
  static handleSelect(_event, suggestion) {
    if (suggestion.url) {
      window.location.href = suggestion.url;
    }
  }

  constructor(props) {
    super(props);

    this.state = {
      searchTerm: '',
    };

    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.search = this.search.bind(this);
    this.handleSearchKeyUp = this.handleSearchKeyUp.bind(this);
    this.handleCursorChange = this.handleCursorChange.bind(this);
    this.handleTypeaheadChange = this.handleTypeaheadChange.bind(this);
  }

  componentDidMount() {
    const { dataSource } = this.props;
    this.$el = $(this.el);

    this.$el.typeahead({
      hint: false,
      highlight: true,
      classNames: {
        menu: 'searchbox__suggestions-menu',
      },
    },
    {
      name: 'search-suggestions',
      display: 'text',
      limit: 10,
      source: (q, cb) => {
        const matches = [];
        const substrRegex = new RegExp(q, 'i');
        $.each(dataSource, (i, data) => {
          if (substrRegex.test(data.text)) {
            matches.push(data);
          }
        });

        cb(matches);
      },
    });

    this.$el.on('typeahead:select', IntroSearch.handleSelect);
    this.$el.on('typeahead:change', this.handleTypeaheadChange);
    this.$el.on('typeahead:cursorchange', this.handleCursorChange);

    // eslint-disable-next-line no-new
    new Typed(this.el, this.getTypedOptions());
  }

  componentWillUnmount() {
    this.$el.off('typeahead:select', IntroSearch.handleChange);
    this.$el.off('typeahead:change', this.handleTypeaheadChange);
    this.$el.off('typeahead:cursorchange', this.handleCursorChange);
    this.$el.typeahead('destroy');
  }

  getTypedOptions() {
    const { dataSource } = this.props;
    const typedStrings = dataSource.map(item => item.text);
    return {
      strings: typedStrings,
      typeSpeed: 40,
      backDelay: 3000,
      attr: 'placeholder',
      bindInputFocusEvents: true,
      loop: true,
    };
  }

  handleSearchKeyUp(event) {
    event.preventDefault();
    if (event.keyCode === 13) {
      this.search();
    }
  }

  handleSearchChange(event) {
    this.setState({
      searchTerm: event.target.value,
    });
  }

  handleCursorChange(event, suggestion) {
    if (suggestion) {
      this.setState({
        searchTerm: suggestion.text,
      });
    } else {
      this.setState({
        searchTerm: event.target.value,
      });
    }
  }

  handleTypeaheadChange() {
    const { searchTerm } = this.state;
    this.$el.val(searchTerm);
  }

  search() {
    const { searchTerm } = this.state;
    const { searchUrl } = this.props;
    if (searchTerm) {
      window.location.href = searchUrl + encodeURI(searchTerm);
    }
  }

  searchWithTerm(term) {
    const { searchUrl } = this.props;
    window.location.href = searchUrl + encodeURI(term);
  }

  render() {
    const { frecuentSearchs } = this.props;
    const { searchTerm } = this.state;
    return (
      <div className="IntroSearch">
        <h3 className="IntroSearch__Subtitle">SONORA <br /> SECRETARÍA DE HACIENDA </h3>
        <h1 className="IntroSearch__Title">NECESITO INFORMACIÓN SOBRE...</h1>
        <div className="IntroSearch__searchbox-wrapper">
          <div className="input-group IntroSearch__searchbox">
            <input
              type="text"
              className="form-control"
              aria-label="Busqueda"
              ref={el => { this.el = el; }}
              value={searchTerm}
              onChange={this.handleSearchChange}
              onKeyUp={this.handleSearchKeyUp}
            />
            <div className="input-group-append" onClick={this.search}>
              <span className="input-group-text"><i className="fas fa-search" /></span>
            </div>
          </div>
          <div className="IntroSearch__frecuent-searchs">
            <span className="frecuent-searchs__text">Búsquedas frecuentes:</span>
            {
              frecuentSearchs.map((item, index) => (
                <span className="frecuent-searchs__term" key={index} onClick={() => { this.searchWithTerm(item); }}>{item}</span>
              ))
            }
          </div>
        </div>
      </div>
    );
  }
}

IntroSearch.defaultProps = {
  dataSource: [
    {
      text: 'Revalidación Vehicular',
      url: `${appConfig.rootPath}personas/revalidacion-vehicular/`,
    },
    {
      text: 'Renovación de Licencia de Conducir',
      url: `${appConfig.rootPath}personas/renovacion-de-licencia-de-conducir/`,
    },
    {
      text: 'Registro Civil',
      url: `${appConfig.rootPath}personas/registro-civil/`,
    },
    {
      text: '2% sobre nómina',
      url: `${appConfig.rootPath}empresas/isrtp/`,
    },
    {
      text: 'Licencia de alcoholes',
      url: `${appConfig.rootPath}empresas/direccion-general-de-bebidas-alcoholicas/`,
    },
    {
      text: 'Transportista',
      url: `${appConfig.rootPath}empresas/transportista/`,
    },
    {
      text: 'Protección Civil',
      url: `${appConfig.rootPath}empresas/proteccion-civil/`,
    },
    {
      text: 'ISAN',
      url: `${appConfig.rootPath}empresas/isan/`,
    },

  ],
};

export default IntroSearch;
