module.exports = {
    rootPath : window.location.protocol + '//' + window.location.host + '/',
    folderAssets: 'build/assets',
    folderMedia: 'build/media',
    finanzas: {
        urlBase: 'finanzas-publicas/',
        sections: {
            planeacion: 'planeacion/',
            programacion: 'programacion/',
            presupuesto: 'presupuestacion/',
            rendicionCuentas: 'rendicion-de-cuentas/',
            evaluacion: 'evaluacion/',
            deuda: 'deuda/',
            estadisticas: 'estadisticas/'
        }
    },
    searchPath: 'buscar/?q=',
    faqPath: 'preguntas-frecuentes/?q=',
};