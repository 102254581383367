import React, { PureComponent } from 'react';

class NewsCard extends PureComponent {
  componentDidMount() {
    const cardsText = document.getElementsByClassName('NewsCard__text');
    for (let i = 0; i < cardsText.length; i++) {
      const wordArray = cardsText[i].innerHTML.split(' ');
      while (cardsText[i].scrollHeight > cardsText[i].offsetHeight) {
        wordArray.pop();
        cardsText[i].innerHTML = `${wordArray.join(' ')}...`;
      }
    }
  }

  render() {
    const { imgUrl, title, text, newsUrl } = this.props;
    return (
      <div className="HomeNews__NewsCard media">
        <img className="NewsCard__image" src={imgUrl} alt="Imagen Noticia" />
        <div className="media-body">
          <h5 className="NewsCard__title">{title}</h5>
          <p className="NewsCard__text">{text}</p>
          <a href={newsUrl} className="NewsCard__link">LEER MÁS</a>
        </div>
      </div>
    );
  }
}

export default NewsCard;
