// Dependencias
import React, { Component } from 'react';
/* global $ */

// Componentes
import HomeIntro from '../../Components/Home/HomeIntro';
import HomeProcedures from '../../Components/Home/HomeProcedures';
import HomeNews from '../../Components/Home/HomeNews';
import LateralButton from '../../Components/Generic/Buttons/LateralButton/Index';

// Hojas de Estilos
import './Index.scss';

class Home extends Component {
  componentDidMount() {
    const { model } = this.props;
    const evt = $.Event('addbuttonevent', {
      btn: <LateralButton className="LateralButton--gold" icon="fas fa-car fa-lg" title="PAGO EXPRESS" link={model.expressPaymentUrl} target="_blank" />,
    });
    const expressLicense = $.Event('addbuttonevent', {
      btn: <LateralButton className="LateralButton--green" icon="fas fa-address-card fa-lg" title="LICENCIA EXPRESS" link={model.expressLicense} target="_blank" />,
    });
    $(window).trigger(evt);
    $(window).trigger(expressLicense);
  }

  render() {
    const { model: { videoSrc, posterSrc, frecuentSearchs, searchUrl, persona, empresa, newsCarousel, news } } = this.props;
    return (
      <div className="container-fluid Home">
        <HomeIntro
          videoSrc={videoSrc}
          posterSrc={posterSrc}
          frecuentSearchs={frecuentSearchs}
          searchUrl={searchUrl}
        />
        <HomeProcedures persona={persona} empresa={empresa} />
        <HomeNews newsCarousel={newsCarousel} news={news} />
      </div>
    );
  }
}

export default Home;
