/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable camelcase */
// Dependencias
import React, { PureComponent } from 'react';
import Slider from 'react-slick';
import appConfig from '../../App.config';

// Componentes
import NewsCard from './News/Card/Card';

// Hojas de Estilos
import './HomeNews.scss';

const PrevArrow = ({ onClick }) => (
  <button type="button" onClick={onClick} className="custom-slick-news-arrow custom-slick-prev-arrow">
    <span className="icon-arrow-left-circle" />
  </button>
);

const NextArrow = ({ onClick }) => (
  <button type="button" onClick={onClick} className="custom-slick-news-arrow custom-slick-next-arrow">
    <span className="icon-arrow-right-circle" />
  </button>
);

class HomeNews extends PureComponent {
  render() {
    const slick_news_settings = {
      dots: true,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 7200,
      infinite: true,
      speed: 900,
      slidesToShow: 1,
      slidesToScroll: 1,
      dotsClass: 'slick-dots HomeNews__carousel-dots',
    };
    const slick_banner_settings = {
      dots: false,
      arrows: true,
      autoplay: true,
      autoplaySpeed: 7200,
      infinite: true,
      speed: 900,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
    };
    const sitesData = {
      bannerCarousel: [
        {
          imgUrl: `${appConfig.rootPath + appConfig.folderAssets}/img/home/alertas/1.png`, link: 'http://www.sonora.gob.mx/',
        },
        {
          imgUrl: `${appConfig.rootPath + appConfig.folderAssets}/img/home/alertas/Logo_FiscaliaSonora.png`, link: 'https://fiscalia.sonora.gob.mx/',
        },
        {
          imgUrl: `${appConfig.rootPath + appConfig.folderAssets}/img/home/alertas/3.png`, link: 'http://sspsonora.gob.mx/',
        },
        {
          imgUrl: `${appConfig.rootPath + appConfig.folderAssets}/img/home/alertas/4.png`, link: 'http://saludsonora.gob.mx/',
        },
        {
          imgUrl: `${appConfig.rootPath + appConfig.folderAssets}/img/home/alertas/5.png`, link: 'https://ism.sonora.gob.mx/',
        },
        {
          imgUrl: `${appConfig.rootPath + appConfig.folderAssets}/img/home/alertas/6.png`, link: 'https://www.gob.mx/conavim',
        },
      ],
      nationalTransparencySite: {
        imgUrl: `${appConfig.rootPath + appConfig.folderAssets}/img/home/transparencia-logo.png`,
        siteUrl: 'http://www.plataformadetransparencia.org.mx/',
      },
      stateTransparencySite: {
        siteUrl: 'http://transparencia.sonora.gob.mx/',
      },
      accountabilitySite: {
        siteUrl: 'http://rindecuentas.sonora.gob.mx/',
      },
    };

    const { newsCarousel, news } = this.props;

    return (
      <div className="HomeNews">
        <h4 className="HomeNews__title">NOTICIAS Y NOVEDADES</h4>
        <div className="row align-items-lg-center">
          <div className="col-12 col-lg-6 order-lg-2 HomeNews__carousel-container">
            <Slider {...slick_news_settings}>
              {
                newsCarousel.map((item, index) => {
                  if (item.newsUrl) {
                    return (
                      <a key={index} href={item.newsUrl} className="HomeNews__carousel-news">
                        <img src={item.imgUrl} alt="Imagen Noticia" />
                      </a>
                    );
                  }
                  return (
                    <img key={index} src={item.imgUrl} alt="Imagen Noticia" className="HomeNews__carousel-news" />
                  );
                })
              }
            </Slider>
          </div>
          <div className="col-12 col-lg-6 order-lg-1 HomeNews__news-container">
            {
              news.map((item, index) => (
                <NewsCard key={index} imgUrl={item.imgUrl} title={item.title} text={item.text} newsUrl={item.newsUrl} />
              ))
            }
          </div>
        </div>
        <div className="row HomeNews__external-container">
          <div className="col-12 col-lg-6 pr-xl-5">
            <h5 className="HomeNews__subtitle">SITIOS DE ATENCIÓN PARA LAS ALERTAS DE VIOLENCIA DE GÉNERO EN SONORA</h5>
            <div className="HomeNews__carousel-banner-container">
              <Slider {...slick_banner_settings}>
                {
                  sitesData.bannerCarousel.map((item, index) => (
                    <a key={index} href={item.link} target="_blank" rel="noopener noreferrer"><img src={item.imgUrl} alt="Imagen Banner" className="HomeNews__carousel-banner" /></a>
                  ))
                }
              </Slider>
            </div>
          </div>
          <div className="col-12 col-lg-6 pl-xl-5 mt-4 mt-lg-0">
            <div className="row">
              <div className="col-12 col-lg-6">
                <h5 className="HomeNews__subtitle">PORTALES DE TRANSPARENCIA</h5>
                <div className="row justify-content-center">
                  <div className="col-12 col-md-8 col-lg-12 HomeNews__link-transparencia-container">
                    <a href={sitesData.nationalTransparencySite.siteUrl} target="_blank" rel="noopener noreferrer" className="d-block">
                      <img src={sitesData.nationalTransparencySite.imgUrl} alt="Portal de transparencia" className="img-fluid" />
                    </a>
                  </div>
                  <div className="col-12 col-md-8 col-lg-12 HomeNews__link-transparencia-container">
                    <a href={sitesData.stateTransparencySite.siteUrl} target="_blank" rel="noopener noreferrer" className="d-table w-100 HomeNews__link-portal-transparencia">
                      <span className="d-table-cell align-middle">PORTAL DE TRANSPARENCIA DEL ESTADO DE SONORA</span>
                    </a>
                  </div>
                  <div className="col-12 col-md-8 col-lg-12 HomeNews__link-transparencia-container">
                    <a href={sitesData.accountabilitySite.siteUrl} target="_blank" rel="noopener noreferrer" className="d-table w-100 HomeNews__link-portal-transparencia link-portal-transparencia--green">
                      <span className="d-table-cell align-middle">PORTAL CUENTAS CLARAS</span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-6 mt-4 mt-lg-0">
                <h5 className="HomeNews__subtitle">SUBASTAS</h5>
                <div className="row justify-content-center">
                  <div className="col-12 col-md-8 col-lg-12 HomeNews__link-transparencia-container SubastasLink">
                    <a href="/contribuyentes/informacion-de-subastas/" target="_top" className="d-table w-100 HomeNews__link-portal-transparencia link-portal-transparencia--golden">
                      <span className="d-table-cell align-middle">SUBASTAS DE BIENES</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

HomeNews.defaultProps = {
  newsCarousel: [
    {
      imgUrl: `${appConfig.rootPath + appConfig.folderAssets}/img/home/400x350.png`, newsUrl: '#',
    },
    {
      imgUrl: `${appConfig.rootPath + appConfig.folderAssets}/img/home/400x350.png`, newsUrl: '',
    },
    {
      imgUrl: `${appConfig.rootPath + appConfig.folderAssets}/img/home/400x350.png`, newsUrl: '#',
    },
  ],
  news: [
    {
      imgUrl: `${appConfig.rootPath + appConfig.folderAssets}/img/home/400x350.png`,
      title: 'Entrega gobernadora carretera y da certeza',
      text: 'La Gobernadora Claudia Pavlovich Arellano Inauguró la rehabilitación de la carretera Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      newsUrl: '#',
    },
    {
      imgUrl: `${appConfig.rootPath + appConfig.folderAssets}/img/home/400x350.png`,
      title: 'Entrega gobernadora carretera y da certeza',
      text: 'Para brindar mejor servicio a los usuarios, se realizó la primera reunión de trabajo con Agentes y Subagentes Fiscales y titulares de la Dirección General de Recaudación, la cual encabezó el Secretario de Hacienda, Raúl Navarro Gallegos.',
      newsUrl: '#',
    },
    {
      imgUrl: `${appConfig.rootPath + appConfig.folderAssets}/img/home/400x350.png`,
      title: 'Entrega gobernadora carretera y da certeza',
      text: 'La Gobernadora Claudia Pavlovich Arellano Inauguró la rehabilitación de la carretera Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      newsUrl: '#',
    },
  ],
};

export default HomeNews;
