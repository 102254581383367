/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from 'react';

import Card from '../Generic/Card/Card';

import './PaymentsList.scss';

class PaymentsList extends PureComponent {
  static redirectToUrl(url) {
    window.location.href = url;
  }

  render() {
    const { type, payments } = this.props;
    return (
      <div className={`row PaymentList${type !== 0 ? ' PaymentList--business' : ''}`}>
        {
          payments.map((item, index) => (
            <Card imageUrl={item.imageUrl} text={item.text} key={index} onClick={() => { PaymentsList.redirectToUrl(item.url); }} />
          ))
        }
      </div>
    );
  }
}

PaymentsList.defaultProps = {
  type: 0,
};

export default PaymentsList;
