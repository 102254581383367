// Dependencias
import React, { PureComponent } from 'react';
import appConfig from '../../App.config';

// Componentes
import PaymentsList from '../Pagos/PaymentsList';

// Hojas de Estilos
import './HomeProcedures.scss';

class HomeProcedures extends PureComponent {
  render() {
    const { persona, empresa } = this.props;
    return (
      <div className="HomeProcedures">
        <h4 className="HomeProcedures__title">ERES:</h4>
        <ul className="nav nav-tabs nav-justified" role="tablist">
          <li className="nav-item">
            <a className="nav-link active" id="persona-tab" data-toggle="tab" href="#persona-content" role="tab" aria-selected="true"><i className="far fa-user" /> Persona</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" id="empresa-tab" data-toggle="tab" href="#empresa-content" role="tab" aria-selected="false"><i className="fas fa-briefcase" /> Empresa</a>
          </li>
        </ul>
        <div className="tab-content">
          <div className="tab-pane show active" id="persona-content" role="tabpanel" aria-labelledby="persona-tab">
            <PaymentsList payments={persona.tramites} />
            <div className="row HomeProcedures__more-btn-wrap">
              <div className="col-8 offset-2 col-lg-4 offset-lg-4">
                <a className="btn btn-block HomeProcedures__more-btn" href={persona.verMasUrl}>VER MÁS</a>
              </div>
            </div>
          </div>
          <div className="tab-pane" id="empresa-content" role="tabpanel" aria-labelledby="empresa-tab">
            <PaymentsList payments={empresa.tramites} type="1" />
            <div className="row HomeProcedures__more-btn-wrap">
              <div className="col-8 offset-2 col-lg-4 offset-lg-4">
                <a className="btn btn-block HomeProcedures__more-btn more-btn--business" href={empresa.verMasUrl}>VER MÁS</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

HomeProcedures.defaultProps = {
  persona: {
    tramites: [
      {
        imageUrl: `${appConfig.rootPath + appConfig.folderAssets}/img/home/350x250.png`, text: 'Revalidación vehícular', url: '/revalidacion',
      },
      {
        imageUrl: `${appConfig.rootPath + appConfig.folderAssets}/img/home/350x250.png`, text: 'Actas de nacimiento', url: '#',
      },
      {
        imageUrl: `${appConfig.rootPath + appConfig.folderAssets}/img/home/350x250.png`, text: 'Renovación de licencia vehícular', url: '#',
      },
      {
        imageUrl: `${appConfig.rootPath + appConfig.folderAssets}/img/home/350x250.png`, text: 'Cartas de no antecedentes penales', url: '#',
      },
      {
        imageUrl: `${appConfig.rootPath + appConfig.folderAssets}/img/home/350x250.png`, text: 'Carta de no servidor público', url: '#',
      },
      {
        imageUrl: `${appConfig.rootPath + appConfig.folderAssets}/img/home/350x250.png`, text: 'Pagos servicios icreson', url: '#',
      },
    ],
    verMasUrl: '/',
  },
  empresa: {
    tramites: [{
      imageUrl: `${appConfig.rootPath + appConfig.folderAssets}/img/home/350x250.png`, text: 'Revalidación vehícular', url: '#',
    }],
    verMasUrl: '/',
  },
};

export default HomeProcedures;
