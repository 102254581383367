import React, { Component } from 'react';

import './Index.scss';

class LateralButton extends Component {
  constructor(props) {
    super(props);
    this.redirectTo = this.redirectTo.bind(this);
  }

  getIcon() {
    const { isActive, icon } = this.props;
    if (isActive) {
      return (<span className={icon} />);
    }
    return (
      <span className="fa-layers fa-fw">
        <i className="fa-inverse fas fa-ban fa-lg" />
        <i className={icon} data-fa-transform="shrink-6" />
      </span>
    );
  }

  redirectTo() {
    const { isActive, link, target } = this.props;
    if (isActive) {
      window.open(link, target);
    }
  }


  render() {
    const { isActive, className, title } = this.props;
    return (
      <button type="button" className={`LateralButton ${isActive ? className : 'LateralButton--grey'}`} onClick={this.redirectTo}>
        {this.getIcon()}
        {
          (title) ? <span className="LateralButton__text">{title}</span> : null
        }
      </button>
    );
  }
}


LateralButton.defaultProps = {
  isActive: true,
  target: '_self',
};

export default LateralButton;
