/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import './Card.scss';

const Card = ({ className, onClick, imageUrl, text }) => (
  <div className={`col-6 col-lg-4 CardComponent ${className || ''}`} onClick={onClick}>
    <a className="card">
      <div className="card-img-top">
        <img src={imageUrl} alt="Imagen" />
      </div>
      <div className="card-body d-flex align-items-center">
        <div className="card-text">
          {text}
        </div>
      </div>
    </a>
  </div>
);

export default Card;
